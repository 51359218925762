/** * @author: LiYaHui * @date: 2021-03-03 * @description: 业态选择 */
<template>
  <el-cascader
      v-model="currentValue"
      v-bind="$attrs"
      :options="list"
      :props="Object.assign({}, defaultProps, props)"
      :disabled="disabled"
      :placeholder="placeholder"
      :size="size"
      filterable
      clearable
      :collapse-tags="collapseTags"
      @change="onChange"
      popper-class="disable-firs-tlevel"
  ></el-cascader>
</template>

<script>
import {FOOD_CA_CODE, RETAIL_CA_CODE, SITE_CA_CODE} from "@/utils/constant";
import {getBizformat} from "@/api/api-join-mall";

export default {
  name: 'FormatSelect',
  props: {
    defaultSelected: {
      type: Array,
    },
    props: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择业态'
    },
    size: {
      type: String,
      default: 'medium'
    },
    collapseTags: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const currentValue = JSON.parse(JSON.stringify(this.defaultSelected || []))
    return {
      list: [],
      data: null,
      mapList: {},
      currentValue,
      retailCaCode: RETAIL_CA_CODE, //商品展示体验赋能零售  模拟零售
      foodCaCode: FOOD_CA_CODE, // 餐饮
      siteCaCode: SITE_CA_CODE, // 场地
      maxFirstLevelSelectCount: 3,
      defaultProps: {
        multiple: true,
        emitPath: false,
        checkStrictly: true,
        label: 'name',
        value: 'caId',
        children: 'children',
        disabled: 'disabled'
      },

    }
  },
  mounted() {
    this.fixCascader()
  },
  updated() {
    this.fixCascader()
  },
  created() {
    // this.list = this.toTree(this.arr, '0', 'caId', 'pid')
    getBizformat().then((res) => {
      const response = res.data
      response.data.forEach(item => {
        this.mapList[item.caId] = item
        // const oldLabelList = item.oldCaInfoList.map(item => (`${item.name}${item.code}`))
        // item.name += `(${oldLabelList.join('、')})`
      })
      this.data = response.data
      this.list = this.toTreeArray(response.data)
      this.onChange(this.currentValue || [])
      this.$emit('onLoaded', response.data, this.list)
    })
  },
  computed: {
    mergeProps() {
      return Object.assign({}, this.defaultProps, this.props)
    }
  },
  methods: {
    // 解决cascader placeholder重影
    fixCascader() {
      const cascader = document.querySelectorAll('.el-cascader__search-input')

      for (let index = 0; index < cascader.length; index++) {
        cascader[index].setAttribute('placeholder', '')
      }
    },
    toTreeArray(data) {
      let map = {}
      data.forEach(item => {
        map[item.caId] = item
      })
      let list = []
      data.forEach(item => {
        if (item.level < 3) {
          this.$set(item, "disabled", false)
          let parent = map[item.parentId]
          if (parent) {
            (parent.children || (parent.children = [])).push(item)
          } else {
            list.push(item)
          }
        }
      })
      return list
    },
    toTree(data, id) {
      let list = []
      data.forEach(item => {
        if (item.parentId == id) {
          if (id == null) {
            let children = this.toTree(data, item.caId)
            if (children.length) {
              item.children = children
            }
          }
          list.push(item)
        }
      })
      return list
    },


    /**
     * 业务变更：
     * 规则一： 场地和餐饮互斥（优先级最好）
     * 规则二： 如果选三个业态  则必须是 零售 + 场地 + 其他（其他要遵循规则一）
     * @param value
     */
    onChange(value) {
      if (this.mergeProps.multiple) {
        this.initCascaderDisable(value);
        this.$emit('input', value)
        this.$emit('change', value, value.map(item => this.mapList[item]))
        return
      }
      this.$emit('input', value)
      this.$emit('change', value, this.mapList[value])
    },

    /**
     * 禁用除了已选中的元素   或解禁所有
     * @param selectedList
     * @param isDisabled
     */
    disableListItem(selectedList, isDisabled) {
      this.data.forEach(item => {
        if (item.level <= 1) {
          item.disabled = false
          return
        }
        if (selectedList.includes(item.caId)) {
          item.disabled = false
          return
        }

        item.disabled = isDisabled
      })
    },

    initCascaderDisable(value) {
      console.log("initCascaderDisable", value)
      this.disableListItem(value, false)
      // 选中的旧一级业态
      const oldFirstLevelCodeSet = new Set()
      value.forEach(item => {
        this.mapList[item].oldCaInfoList.forEach(caInfo => {
          oldFirstLevelCodeSet.add(caInfo.code.substring(0, 3))
        })
      })

      // 最多选三个旧业态
      const oldFirstLevelCodeList = Array.from(oldFirstLevelCodeSet)
      if (oldFirstLevelCodeList.length > 3) {
        this.disableListItem(value, true)
        return
      }

      // 没有数据全部解禁
      if (!value.length) {
        this.disableListItem(value, false)
        return;
      }

      this.data.forEach(item => {
        item.disabled = false
        if (item.level <= 1) {
          return
        }

        // item包含的老业态类型
        const secondCodeSubList = item.oldCaInfoList.map(caInfo => caInfo.code.substring(0, 3))

        const sumCodeList = Array.from(new Set([...secondCodeSubList, ...oldFirstLevelCodeList]))
        // 若勾选后业态之和为3  则禁用
        if (sumCodeList.length > 3) {
          console.log("sumCodeList > 3")
          item.disabled = true
          return;
        }

        // 勾选够若包含场地 + 餐饮  禁用
        if (sumCodeList.includes(this.foodCaCode) && sumCodeList.includes(this.siteCaCode)) {
          console.log("场地 + 餐饮")
          item.disabled = true
          return;
        }

        // 若勾选之后正好为三个，则必须包含零售 + 场地
        if (sumCodeList.length === 3) {
          if (!sumCodeList.includes(this.retailCaCode) || !sumCodeList.includes(this.siteCaCode)) {
            console.log("sumCodeList === 3 零售 + 场地")
            item.disabled = true
            return;
          }
        }

        if (sumCodeList.length === 2) {
          // 如果选择两个一级业态，即任意一级业态+“零售”或者除“餐饮”业态外其他一级业态+“场地”   餐饮场地互斥逻辑在上面处理过了
          if (!sumCodeList.includes(this.retailCaCode) && !sumCodeList.includes(this.siteCaCode)) {
            item.disabled = true
          }
        }
      })
    },
  }
}
</script>

<style lang="scss">
.disable-firs-tlevel {
  .el-cascader-menu:first-of-type {
    .el-checkbox {
      display: none;
    }
  }
}
</style>
